export const vars = {
  white: "#fff",
  black: "#000",
  content_width: "calc(100% - 240px)",
  m_left_1: "11.33%",
  c_left_1: "17.2%",
  header_height: "80px",
  f_title_mobile: "36px",
  mobile_margin_down: "34px",
  mobile_margin_sides: "20px"
};
